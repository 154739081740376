<footer>
    <!-- <div class="footer-wrap clearfix">
        <div class="row">
            <div class="col-md-4 col-sm-6 footer-col" *ngIf="hasFirstColumn">
                <ng-container *ngIf="!disableNavigation">
                    <h3>{{ "footer.navegacion" | t }}</h3>
                    <ul>
                        <ng-container *ngFor="let item of itemsMenu">
                            <li>
                                <a [routerLink]="item.route" role="button">
                                    {{ item.name }}
                                </a>
                            </li>
                        </ng-container>
                    </ul>
                </ng-container>
                <ng-container *ngIf="moveLogoToFirstColumn">
                    <ng-container *ngTemplateOutlet="logoTemplate"></ng-container>
                </ng-container>
            </div>

            <div class="col-md-4 col-sm-6 footer-col" [class.col-md-offset-4]="!hasFirstColumn" *ngIf="hasSecondColumn">
                <h3>{{ "footer.informacion_contacto" | t }}</h3>
                <ul>
                    <li *ngFor="let item of informacionContacto">
                        <span *ngIf="isString(item); else keyValue">
                            {{ item }}
                        </span>
                        <ng-template #keyValue>
                            <span *ngIf="!isString(item)">{{ item.label }}: {{ item.value }}</span>
                        </ng-template>
                    </li>
                </ul>
            </div>

            <div
                class="col-md-4 col-sm-6 footer-col"
                [class.col-md-offset-4]="hasFirstColumn && !hasSecondColumn"
                [class.col-md-offset-8]="!hasFirstColumn && !hasSecondColumn"
                *ngIf="showSocial && hasSocial"
            >
                <h3>{{ "footer.social_titulo" | t }}</h3>
                <ul class="socialMedia">
                    <ng-container *ngFor="let key of socials | keys">
                        <li *ngIf="socials[key]">
                            <a
                                *ngIf="key === 'tiktok'; else social"
                                href="{{ config.plataforma.info.social.tiktok }}"
                                target="_blank"
                                title="TikTok"
                                class="tiktok"
                            >
                                <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="tiktok"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                    class="svg-inline--fa fa-tiktok fa-w-14"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
                                        class=""
                                    ></path>
                                </svg>
                            </a>
                            <ng-template #social>
                                <a [href]="socials[key]" [class]="key" target="_blank" role="button">
                                    <fa [name]="key"></fa>
                                </a>
                            </ng-template>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>-->

    <div class="bottom-footer" *ngIf="!moveLogoToFirstColumn || logosExtras?.length > 0">
        <ng-container *ngIf="showLogoWithExtras && !moveLogoToFirstColumn">
            <ng-container *ngTemplateOutlet="logoTemplate"></ng-container>
        </ng-container>

        <ng-container *ngFor="let logoExtra of logosExtras">
            <a *ngIf="enableLogoFooter" class="footer-logo" href="{{ logoExtra.link }}">
                <img src="{{ logoExtra.src }}" alt="{{ logoExtra.title }}" title="{{ logoExtra.title }}" />
            </a>
        </ng-container>
    </div>
</footer>

<ng-template #logoTemplate>
    <a *ngIf="enableLogoFooter" class="footer-logo" routerLink="/landing">
        <img
            src="{{ logo }}"
            alt="{{ config.plataforma.info.companyName }}"
            title="{{ config.plataforma.info.companyName }}"
        />
    </a>
</ng-template>
