import {
    PlanClaseAlumnoComponent,
    ClasePlanClaseComponent,
    PlanClasesAlumnosComponent,
    PlanClasesDeAlumnosComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

const routes: Routes = [
    {
        path: "plan_clases",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClasesDeAlumnosComponent,
                data: {
                    planClaseRoute: "/plan_clases",
                    showAsignaturas: false
                },
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "plan_clases/:id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClaseAlumnoComponent,
                canActivate: [AuthGuard],
                data: { titulo: "Aulas - Planos de estudo" }
            }
        ]
    },
    {
        path: "plan_clases/:id/sesiones/:sesionId",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: ClasePlanClaseComponent,
                canActivate: [AuthGuard],
                data: {
                    titulo: "Sessão"
                }
            }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AlumnosPlanClasesRouting {}
