<ng-container *ngIf="!saved && form">
    <form novalidate [formGroup]="form">
        <div class="row">
            <div class="col-md-6">
                <h3>Dados pessoais</h3>
                <form-input [form]="form" [params]="params['id']" [(value)]="usuario['id']" [key]="'id'"></form-input>
                <div>
                    <div>
                        <label class="label-completado">Nomes</label>
                        <p class="valor-completado">{{ usuario.nombre }}</p>
                    </div>
                    <div>
                        <label class="label-completado">Sobrenome</label>
                        <p class="valor-completado">{{ usuario.apellido_paterno }}</p>
                    </div>
                    <div>
                        <label class="label-completado">Sobrenome</label>
                        <p class="valor-completado">{{ usuario.apellido_materno | easyplaceholder: "-" }}</p>
                    </div>
                    <div>
                        <label class="label-completado">CPF</label>
                        <p class="valor-completado">{{ usuario["usuario_" + pais].cpf }}</p>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <form-input [form]="form" [params]="params['password']" [enablePasswordBar]="true"
                    [(value)]="usuario['password']" [key]="'password'"></form-input>
                <form-input [form]="form" [params]="params['password_confirmation']"
                    [(value)]="usuario['password_confirmation']" [key]="'password_confirmation'"></form-input>
                <form-input [form]="form" [params]="params['terminos_y_condiciones']"
                    [(value)]="usuario['terminos_y_condiciones']" [key]="'terminos_y_condiciones'"></form-input>

                <button (click)="save()" type="button" class="btn btn-default btn-block submit-btn">
                    Actualizar
                </button>
                <button (click)="back()" type="button" class="btn btn-default btn-block cancel-btn">
                    Voltar a la página de Inicio
                </button>
            </div>
        </div>
    </form>
</ng-container>

<ng-container *ngIf="saved && mailChanged">
    Enviamos um e-mail para o seu e-mail com instruções para validar sua conta. Se não chegar em 5 minutos ou mais,
    verifique sua pasta de spam.
</ng-container>

<ng-container *ngIf="saved && !mailChanged">
    Seus dados foram atualizados com sucesso. Em alguns segundos iremos redirecioná-lo para a página principal.
</ng-container>