<div class="contenedor">
    <!-- Login -->
    <div id="section1">
        <div class="caja caja-inicio">
            <div class="fading-image" [lazyLoad]="landingImage" [offset]="200"></div>
            <div class="caja-inner-margin">
                <div class="landing-logo">
                    <img
                        class="logo"
                        src="{{ logo }}"
                        alt="{{ config.plataforma.info.companyName }}"
                        title="{{ config.plataforma.info.companyName }}"
                    />
                </div>
                <h1 class="titulo" *ngIf="titulo">{{ titulo }}</h1>
                <login-standard
                    class="login"
                    [enableContactUs]="false"
                    [enableRegister]="enableRegister"
                    [enableFacebookLogin]="false"
                    [enablePasswordRecovery]="false"
                ></login-standard>
                <br />
            </div>
        </div>
        <footer-component></footer-component>
    </div>
</div>
