import { ChangeDetectorRef, Component, EventEmitter, Injector, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import {
    Establecimientos,
    GrupoUsuarios,
    Lugar,
    Lugares,
    Usuarios,
    UsuariosCompletarRegistroFundacoesForm
} from "@puntaje/puntaje/api-services"
import { AppConfig, AuthService, BaseForm, GenericModalComponent, SessionService } from "@puntaje/shared/core"
import { BaseRegisterCompletarComponent } from "../base_register_completar.component"

declare const config: AppConfig

@Component({
    selector: "usuario-completar-fundacoes",
    templateUrl: "./register-completar-fundacoes.component.html",
    styleUrls: ["./register-completar-fundacoes.component.scss"]
})
export class RegisterCompletarFundacoesComponent extends BaseRegisterCompletarComponent {
    formType: typeof BaseForm = UsuariosCompletarRegistroFundacoesForm
    lugarColegio: Lugar
    filteredLugarColegio: Lugar[]
    identificadorUsuarioAlias: string

    constructor(
        usuariosService: Usuarios,
        authService: AuthService,
        sessionService: SessionService,
        router: Router,
        injector: Injector,
        grupoUsuariosService: GrupoUsuarios,
        cdr: ChangeDetectorRef,
        lugaresService: Lugares,
        establecimientosService: Establecimientos
    ) {
        super(
            usuariosService,
            authService,
            sessionService,
            router,
            injector,
            grupoUsuariosService,
            cdr,
            lugaresService,
            establecimientosService
        )

        this.formType = UsuariosCompletarRegistroFundacoesForm

        this.params = this.formType.formParams
        this.usuarioPaisParams = this.params["usuario_" + this.pais].class.formParams
    }

    ngOnInit() {
        super.ngOnInit()
    }
}
